.loading-page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

body[data-theme='light'] .loading-page {
  background-color: #f0f0f0;
}

body[data-theme='dark'] .loading-page {
  background-color: #000000;
}
